import request from '@/utils/edp/request.js'

// 获取面包屑
export const apiViceNavigation = (params) =>
  request({
    method: 'get',
    url: '/api/crumbs',
    params
  })

// 获取副导航栏
export const apiNavigationBar = (params) => request({
  method: 'get',
  url: '/api/edpCateSub',
  params
})

// 证书查询
export const apiQuery = (data) =>
  request({
    method: 'post',
    url: '/api/certQuery',
    data
  })

// 在线报名
export const apiSignup = (data) =>
  request({
    method: 'post',
    url: '/api/online_reg/add',
    data
  })
