<template>
  <!-- 查询成功 -->
  <div class="type">- 查询成功 -</div>
  <div class="exhibition">
    <div class="left">
      <img :src="$img + props.data.head_img" alt="" />
    </div>
    <div class="right">
      <div class="li">
        <div class="label"><p>证书名称</p>:</div>
        <p>{{ props.data.title }}</p>
      </div>
      <div class="li">
        <div class="label"><p>姓 名</p>:</div>
        <p>{{ props.data.name }}</p>
      </div>
      <div class="li">
        <div class="label"><p>证书编号</p>:</div>
        <p>{{ props.data.cert_no }}</p>
      </div>
      <div class="li">
        <div class="label"><p>证书级别</p>:</div>
        <p>{{ props.data.cert_level }}</p>
      </div>
      <div class="li">
        <div class="label"><p>授予日期</p>:</div>
        <p>{{ day(props.data.cert_time).format('YYYY年MM月DD日') }}</p>
      </div>
      <div class="end">中国科学院大学经济与管理学院社会服务与EDP中心</div>
    </div>
  </div>
</template>

<script setup>
  import day from 'dayjs'
  let props = defineProps({
    data: {
      type: Object,
      default: null
    }
  })
</script>

<style lang="scss" scoped>
  .type {
    width: 100%;
    height: .625rem;
    background: #de3a00;
    opacity: 1;
    font-size: .2344rem;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .exhibition {
    width: 100%;
    height: 3.2813rem;
    margin-top: .2969rem;
    display: flex;
    justify-content: space-between;

    .left {
      width: 2.0625rem;
      height: 2.7344rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 6.4609rem;

      > div {
        display: flex;
        align-items: center;
        padding-left: .2891rem;
      }

      .li {
        width: 100%;
        height: .5469rem;
        background-color: #f4f4f4;

        .label {
          width: .8203rem;
          height: .2422rem;
          font-size: .1875rem;
          font-weight: 400;
          color: #6e6e6e;
          line-height: .25rem;
          display: flex;
          align-items: center;
          margin-right: .5391rem;
          > p {
            flex: 1;
            text-align-last: justify;
          }
        }

        p {
          font-size: .1875rem;
          font-weight: 400;
          color: #6e6e6e;
          line-height: .25rem;
        }
      }

      .end {
        height: .5469rem;
        width: 100%;
        border-bottom: .0078rem solid #a3a3a3;
        font-size: .1563rem;
        font-weight: 400;
        color: #a6291a;
      }

      & .li:nth-child(even) {
        background: #e8e8e8;
      }
    }
  }
</style>
