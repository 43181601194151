<template>
  <div class="query">
    <div class="box">
      <div>
        <div class="label"><span>证书编号</span>:</div>
        <input type="text" placeholder="请输入证书编号" v-model.trim="data.certificate" />
      </div>

      <div>
        <div class="label"><span>证件编号</span>:</div>
        <input type="text" placeholder="请输入证件编号" v-model.trim="data.certificates" />
      </div>

      <div>
        <div class="label"><span>姓 名</span>:</div>
        <input type="text" placeholder="请输入姓名" v-model.trim="data.name" />
      </div>
      <p>注：以上三项输入任意两项即可查询</p>
    </div>
    <button @click="handlerSubmit">查&nbsp;&nbsp;&nbsp;询</button>
  </div>
</template>

<script setup>
  import { reactive } from 'vue'
  import { ElMessage } from 'element-plus'
  import { apiQuery } from '@/api/layout'
  let data = reactive({
    certificate: null, // 证书编号
    certificates: null, // 证件编号
    name: '' //姓名
  })
  // 提交
  // let checkNum = new RegExp(/^-?\d+$/)
  const $emit = defineEmits(['queryResults'])
  const handlerSubmit = async () => {
    let checkNum = []
    for (let key in data) {
      if (data[key]) {
        checkNum.push(true)
      }
    }
    // 当有两项内容时发送请求
    if (checkNum.length >= 2) {
      let result = await apiQuery({
        cert_no: data.certificate, // 证书编号
        name: data.name,
        id_card_no: data.certificates // 证件编号
      })
      $emit('queryResults', result)
    } else {
      ElMessage.error('当前输入少于两项')
    }
  }
</script>

<style lang="scss" scoped>
  .query {
    .box {
      width: 100%;
      height: 3.0156rem;
      background: url(~@/assets/edp/images/layout/query.png) no-repeat;
      background-size: 100% 100%;
      background-position: top left;
      display: flex;
      flex-direction: column;
      padding-top: .5391rem;
      padding-left: 1.9453rem;
      > div {
        display: flex;
        align-items: center;
        padding-bottom: .25rem;
        .label {
          display: flex;
          width: 1.25rem;
          height: .3125rem;
          font-size: .2344rem;
          font-weight: 500;
          color: #a6291a;
          line-height: .25rem;
          padding-right: .2344rem;
          > span {
            flex: 1;
            text-align-last: justify;
          }
        }

        > input {
          width: 5.0781rem;
          height: .4375rem;
          background: #ffffff;
          border-radius: .0625rem .0625rem .0625rem .0625rem;
          opacity: 1;
          border: .0078rem solid #de3a00;
          padding: .0938rem 0px .0938rem .1719rem;
          font-size: .1719rem;

          &::placeholder {
            font-size: .1719rem;
            color: #cbcbcb;
            letter-spacing: .0547rem;
            line-height: .3438rem;
          }
        }
      }

      p {
        height: .2031rem;
        font-size: .1563rem;
        font-weight: 400;
        color: #6e6e6e;
        line-height: .25rem;
        margin-left: 3.875rem;
      }
    }

    > button {
      width: 100%;
      height: .625rem;
      background: #de3a00;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: none;
      font-size: .2344rem;
      font-weight: bold;
      color: #ffffff;
      line-height: .25rem;
      margin-top: .2344rem;
      cursor: pointer;
    }
  }
</style>
