<template>
  <!-- <content-title title="证书查询" /> -->
  <!-- 证书查询 -->
  <query-view @queryResults="handlerQuery" v-if="result == -1" />
  <success-view :data="data.success" v-if="result == 200" />
  <fail-view v-if="result == 0" />
</template>

<script setup>
  import { ref, reactive } from 'vue'
  import queryView from './component/query.vue'
  import successView from './component/success.vue'
  import failView from './component/fail.vue'
  let result = ref(-1)
  let data = reactive({
    success: {}
  })
  const handlerQuery = (v) => {
    result.value = v.code
    data.success = v.data
  }
</script>

<style lang="scss" scoped></style>
