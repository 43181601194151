<template>
  <div class="fail">
    <span>- 查询失败 -</span>
  </div>
  <div class="_can_t_find">
    <span>未查询到相关证书信息</span>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
  .fail {
    width: 100%;
    height: .625rem;
    background: #6e6e6e;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .2344rem;
    font-weight: bold;
    color: #ffffff;
  }

  ._can_t_find {
    width: 100%;
    height: 1.0938rem;
    background: #f4f4f4;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-top: .2969rem;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: .1875rem;
      font-weight: 400;
      color: #6e6e6e;
      line-height: .25rem;
    }
  }
</style>
